@import '../shared.module.scss';

.visitorAmount {
  max-width: 14ch;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;
  font-size: 1.6rem;
}

.trackContainer {
  @extend .layout-row;
  width: 230px;
  justify-content: space-between;
}

.button {
  margin-top: 4px;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  font-size: 1.6rem;
}
