@import '../shared.module.scss';

.infoContainer {
  text-align: center;
}

.infoBox {
  position: relative;
  text-align: left;
  display: inline-block;
  margin: 0 auto;
  padding-top: 10px;
  max-width: 70%;
  min-width: 40%;
  word-break: break-word;
}

// TO DO: If needed, move away from absolute positioning
.closeIcon {
  cursor: pointer;
  position: absolute;
  right: 3px;
  top: 13px;
}