@import '../shared.module.scss';

.infoBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 1em;
}

.exclamation2 {
  position: relative;
  top: 30px;
  margin: 0;
  width: 80%;
  max-height: 80%;
  max-width: 80%;
}

.arrivalTime {
  margin: 0;
  padding: 0;
  font-size: 0.8em;
  color: $COLOR_BLACK_TINT_70;
}

.bigContainer {
  width: 100vw;
  max-width: 1025px;
  margin: auto;
}

.flexContainer {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  background-color: $COLOR_BLACK_TINT_70;
  border-right: 0px;
  border-radius: 5px;
  padding-left: 2px;
  padding-bottom: 2px;
  padding-right: 2px;
}

.flexContainer2 {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  text-align: center;
  max-height: 50px;
  background-color: $COLOR_BLACK_TINT_70;
  border: 3px solid $COLOR_BLACK_TINT_70;
  border-radius: 5px 5px 0 0;
  margin-top: -5px;
  margin-bottom: -5px;
}

.flexContainer2 ~ .flexContainer2 {
  border-top: 0px;
}

.dateHeader {
  height: 10em;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.flexContainer2 > a {
  //outline: 0px $COLOR_BLACK;
  background-color: $COLOR_BLACK_TINT_70;
  width: calc(100% / 7);
  text-align: center;
  line-height: 30px;
  font-size: 20px;
  text-decoration: none;
  color: $COLOR_WHITE;
}

.flexContainer > a {
  //border-right: 1px solid;

  width: calc(100% / 7);
  margin: 3px;
  text-align: center;
  height: 125px;
  text-decoration: none;
  color: $COLOR_WHITE;
  border-radius: 5px;
}

.mainGrid {
  direction: column;
  justify-content: left;
  align-items: center;
}

.arrowRight {
  margin-left: 1vw;
}

.arrowLeft {
  margin-right: 1vw;
}

.progress {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.hoverHand:hover {
  cursor: pointer;
}

.klockan {
  margin-left: 16vw;
}

/* media files */

/* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
@media only screen and (min-width: 320px) {
}
/* smartphones, Android phones, landscape iPhone */
@media only screen and (min-width: 480px) {
  .exclamation2 {
    top: 15px;
    width: 70%;
    max-height: 70%;
    max-width: 70%;
    padding-top: 10%;
  }
}
/* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
@media only screen and (min-width: 600px) {
  .exclamation2 {
    width: 60%;
    max-height: 60%;
    max-width: 60%;
    padding-top: 10%;
  }
}
/* tablet, landscape iPad, lo-res laptops ands desktops */
@media only screen and (min-width: 801px) {
  .exclamation2 {
    padding-top: 0;
  }
}
/* big landscape tablets, laptops, and desktops */
@media only screen and (min-width: 1025px) {
}
/* hi-res laptops and desktops */
@media only screen and (min-width: 1281px) {
}
