@import '../../shared.module.scss';

.title {
  margin: 20px 20px 10px;
}

.textField {
  background-color: $COLOR_WHITE;
  border-radius: 4px;
}

.button {
  background-color: $COLOR_BLUE !important;
}

.alert {
  margin: 20px;
}
