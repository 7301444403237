@import '../shared.module.scss';

.hoverHand:hover {
  cursor: pointer;
}

.rangeOfficerStatus {
  border-radius: 10px;
  background-color: $COLOR_GREEN;
  padding: 10px;
  margin: 0px auto 10px auto; /* Centered */
  text-align: center;
}

:global(.MuiButton-label) {
  font-size: 1em; /* Smaller font size */
}

:global(.MuiButton-contained) {
  color: $COLOR_BLACK;
}

.Status {
  display: flex;
  width: 400px;
  margin: auto;
}

.Text {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 1.2rem; /* Smaller font size */
  margin: 6px;
}

.rowStyle {
  flex-direction: row;
  display: flex;
  justify-content: center; /* Centered */
  border-bottom: 1px solid black; /* Separator line */
  margin: 10px 10px; /* Adds space above and below */
}

.trackRowStyle {
  flex-direction: row;
  display: flex;
  justify-content: center; /* Centered */
  flex-wrap: wrap;
  gap: 10px;
  border-bottom: 1px solid black; /* Separator line */
  
}

.colorButton {
  font-size: 14px; /* Smaller font size */
  border-radius: 5px; /* Rectangular buttons */
  width: 180px; /* Smaller button width */
  height: 50px; /* Smaller button height */
  margin: 7px;
}

.rowStyle > .greenButtonStyle {
  @extend .colorButton;
  background-color: $COLOR_GREEN;
}

.rowStyle > .orangeButtonStyle {
  @extend .colorButton;
  background-color: $COLOR_ORANGE;
}

.rowStyle > .redButtonStyle {
  @extend .colorButton;
  background-color: $COLOR_RED_LIGHT;
}

.saveButtonStyle {
  background-color: $COLOR_BLUE;
}

.cancelButtonStyle {
  background-color: $COLOR_BLACK_TINT_10;
}

.simpleButton {
  padding: 2px 10px;
  border-radius: 5px; /* Smaller border radius */
  font-size: 1rem; /* Smaller font size */
}

.rangeStyle {
  text-align: center;
  margin: 10px; /* Smaller margin */
}

.dialogStyle {
  background-color: $COLOR_WHITE;
}

.rangediv {
  width: 250px; /* Smaller width */
}

.statusStyle {
  border-radius: 3px;
  width: 400px;
  margin: 4px;
  margin-bottom: 0px;
}

.buttonStyle {
  border-radius: 5px; /* Rectangular buttons */
  width: 200px; /* Smaller width */
}