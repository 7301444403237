@import '../shared.module.scss';

.container {
  padding: 0 1vh 2vh 1vh;

  .titleContainer,
  .firstSection {
    @extend .layout-row;
    align-items: center;
    text-align: start;
    justify-content: space-around;
    flex-wrap: wrap;
  }
}

.dateHeader {
  @extend .layout-row;
  height: 10em;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.buttonContainer {
  @extend .layout-row;
  align-items: center;
  justify-content: center;
}

.charts {
  @extend .layout-column;
  width: 100%;
  align-items: center;
}
