@import '../shared.module.scss';

.loggingContainer {
  @extend .layout-row;
  width: auto;
  padding: 35px;
  align-items: center;
  justify-content: center;
  background-color: $COLOR_BLACK_TINT_20;
}

.inputContainer {
  @extend .layout-column;
  align-items: center;
  justify-content: center;
}

.visitorInput {
  margin: 15px 10px;
}

.modalButtonContainer {
  @extend .layout-row;
}

.modalButton {
  margin: 40px 4px 0 4px;
}
