@import '../shared.module.scss';

.hoverHand:hover {
  cursor: pointer;
}

.arrowLeft {
  margin-right: 1vw;
}

.arrowRight {
  margin-left: 1vw;
}

.progress {
  display: flex;
  margin: auto;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.notCurMonth {
  opacity: 0.6;
}

.weekdays {
  text-align: center;
  justify-content: center;
  display: flex;
  width: 100%;
  background-color: $COLOR_BLACK_TINT_70;
  border-bottom: 1px solid $COLOR_BLACK_TINT_70;
  border-radius: 5px;
  margin-top: -5px;
  margin-bottom: -5px;
}

.weekdays > div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% / 7 - 2px);
  height: 5vh;
  min-height: 48px;
}

.monthContainer {
  justify-content: center;
  text-align: center;
  margin: auto;
  display: flex;
  width: 100vw;
  max-width: 1025px;
  flex-wrap: wrap;
  font-size: large;
}

.monthDays {
  display: flex;
  background-color: $COLOR_BLACK_TINT_70;
  text-align: center;
  justify-content: center;
  font-weight: bold;
  flex-wrap: wrap;
  width: 100%;
  //border: 1px solid red;
  border-bottom: 2px solid $COLOR_BLACK_TINT_70;
  margin-left: -4px;
  border-left: 4px solid $COLOR_BLACK_TINT_70;
}
.monthDays > a {
  color: $COLOR_BLACK_TINT_80;
  font-weight: bold;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% / 7 - 5px);
  height: 10vh;
  min-height: 48px;
  font-weight: normal;
  margin: 2px;
  border-radius: 3px;
}

.weekNumber {
  background-color: $COLOR_BLACK_TINT_70;
  display: none;
  text-align: center;
  justify-content: left;
  flex-wrap: wrap;
  width: calc(100% / 8);
  border-radius: 5px;
  //margin-right: -5px;
}

.weekdays .weekNumber {
  justify-content: center;
  display: none;
  background-color: $COLOR_BLACK_TINT_70;
  width: calc(100% / 8 - 6px);
  border-radius: 4px;
}

.weekNumber > a {
  color: $COLOR_WHITE;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 10vh;
  min-height: 48px;
  border: 1px solid $COLOR_BLACK_TINT_70;
  
}

.dateHeaderM {
  height: 10em;
  width: 99vw;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.MonthviewInfo {
  position: absolute;
  margin-top: 2pt;
  width: 20px;
  height: 20px;
}

.monthviewDayText {
  font-weight: bold;
}
.monthviewDayImg {
  margin-right: 22px;
}

/* media files */

/* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
@media only screen and (min-width: 320px) {
}

/* smartphones, Android phones, landscape iPhone */
@media only screen and (min-width: 480px) {
  .MonthviewInfo {
    margin-left: 3px;
  }
  .monthviewDayImg {
    margin-right: 16px;
  }

  .weekNumber {
    display: flex;
  }

  .monthDays {
    width: calc(100% / 8 * 7);
  }

  .weekdays .weekNumber {
    display: flex;
  }

  .weekdays > a {
    width: calc(100% / 8 - 2px);
  }

  .weekdays > div {
    background-color: $COLOR_BLACK_TINT_70;
    color: $COLOR_WHITE;
    width: calc(100% / 8 - 2px);
  }
}

/* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
@media only screen and (min-width: 600px) {
}

/* tablet, landscape iPad, lo-res laptops ands desktops */
@media only screen and (min-width: 801px) {
}

/* big landscape tablets, laptops, and desktops */
@media only screen and (min-width: 1025px) {
}

/* hi-res laptops and desktops */
@media only screen and (min-width: 1281px) {
}
