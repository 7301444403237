@import '../shared.module.scss';

.dialogStyle {
  background-color: $COLOR_WHITE;
}

.errorText {
  font-size: 20px;
  color: $COLOR_RED !important;
  text-align: center;
}

.header {
  text-align: center;
  margin: 24px 0px 24px 0px;
}

.lightgreenButton {
  background-color: $COLOR_GREEN_LIGHT;
  color: $COLOR_BLACK;
  margin: 15px;
  text-align: center;
  text-transform: none;
  text-wrap: nowrap;
  font-size: 0.8rem;
  border-radius: 20px;
  padding: 6px 16px 6px 16px;
}

.LoggedIn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 600px;
  width: 80%;
  padding: 20px 20px 20px 20px;
  margin: 0 auto;
  gap: 18px;
  background-color: $COLOR_BLACK_TINT_05;
  border-radius: 10px;
  border: 1px solid #e7e7e7;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  
  .buttonContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 12px;

    .sandButton {
      background-color: $COLOR_SAND;
      color: $COLOR_BLACK;
      margin: 6px;
      text-align: center;
      font-size: 0.8rem;
      border-radius: 20px;
      padding: 6px 20px 6px 20px;
    }
    .turquoiseButton {
      background-color: $COLOR_TURQUOISE;
      color: $COLOR_BLACK;
      margin: 6px;
      text-align: center;
      font-size: 0.8rem;
      border-radius: 20px;
      padding: 6px 20px 6px 20px;
    }
  }
}
.usersHeaderMobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 12px auto 0 auto;

  .header {
    margin: 15px 15px 15px 15px;
  }
}

.tableContainer {
  table-layout: auto;
  justify-content: center;
  width: 90%;
  margin: 0 auto;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 16px;

  .table {
    background-color: $COLOR_BLACK_TINT_05;
  }
  .MuiTableRow-root {
    padding: 8px;
  }
  .buttonCell {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 12px;

    .turquoiseButton {
      background-color: $COLOR_TURQUOISE;
      color: $COLOR_BLACK;
      text-align: center;
      text-wrap: nowrap;
      text-transform: none;
      font-size: 0.8rem;
      border-radius: 20px;
      padding: 6px 16px 6px 16px;
    }
    .removeButton {
      background-color: $COLOR_RED_LIGHT;
      color: $COLOR_BLACK;
      text-align: center;
      text-wrap: nowrap;
      text-transform: none;
      font-size: 0.8rem;
      border-radius: 20px;
      padding: 6px 16px 6px 16px;
    }
  }
  .tableCellMobile {
    .buttonsMobile {
      .turquoiseButton {
        background-color: $COLOR_TURQUOISE;
        color: $COLOR_BLACK;
        text-align: center;
        text-wrap: nowrap;
        text-transform: none;
        font-size: 0.8rem;
        border-radius: 10px;
        padding: 6px 16px 6px 16px;
        margin: 8px;
      }
      .removeButton {
        background-color: $COLOR_RED_LIGHT;
        color: $COLOR_BLACK;
        text-align: center;
        text-wrap: nowrap;
        text-transform: none;
        font-size: 0.8rem;
        border-radius: 10px;
        padding: 6px 16px 6px 16px;
        margin: 8px;
      }
    }
  }
}

.selectRole {
  margin-top: 20px;
  min-width: 160px;
}
.selectAssociation {
  min-width: 160px;
}

@media (min-width: 320px) and (max-width: 599px) {
  .addUserButtonDesk {
    display: none;
  }
  .usersHeaderDesk {
    display: none;
  }
  .tableCellDesk {
    display: none;
  }
  .lightgreenButton {
    text-wrap: wrap;
    line-height: normal;
    min-width: 100px;
    padding: 8px 16px 8px 16px;
  }
}
@media (min-width: 600px) and (max-width: 1099px) {
  .usersHeaderMobile {
    display: none;
  }
  .tableCellDesk {
    display: none;
  }
}
@media (min-width: 1100px) {
  .usersHeaderMobile {
    display: none;
  }
  .tableCellMobile {
    display: none;
  }

}
