$COLOR_GREEN: #658f60;
$COLOR_GREEN_LIGHT: #b2d9ad;
$COLOR_GREEN_DARK: #1e5c3b;
$COLOR_RED: #c23a3a;
$COLOR_RED_LIGHT: #c97b76;
$COLOR_ORANGE: #f2c66d;
$COLOR_TURQUOISE: #95d5db;
$COLOR_BLUE: #5f77a1;

$COLOR_GREY: #555555;
$COLOR_LIGHT_GREY: #808080;
$COLOR_BLACK: #000000;
$COLOR_WHITE: #ffffff;
$COLOR_SAND: #d1ccc2;

$COLOR_BLACK_TINT_80: #303030;
$COLOR_BLACK_TINT_70: #4d4d4d;
$COLOR_BLACK_TINT_60: #666666;
$COLOR_BLACK_TINT_50: #808080;
$COLOR_BLACK_TINT_40: #999999;
$COLOR_BLACK_TINT_30: #b3b3b3;
$COLOR_BLACK_TINT_20: #cccccc;
$COLOR_BLACK_TINT_10: #e5e5e5;
$COLOR_BLACK_TINT_05: #f2f2f2;

:export {
  green: $COLOR_GREEN;
  greenLight: $COLOR_GREEN_LIGHT;
  greenDark: $COLOR_GREEN_DARK;
  red: $COLOR_RED;
  redLight: $COLOR_RED_LIGHT;
  orange: $COLOR_ORANGE;
  turquoise: $COLOR_TURQUOISE;
  blue: $COLOR_BLUE;

  grey: $COLOR_GREY;
  lightGrey: $COLOR_LIGHT_GREY;
  white: $COLOR_WHITE;
  sand: $COLOR_SAND;
  black: $COLOR_BLACK;

  blackTint80: $COLOR_BLACK_TINT_80;
  blackTint70: $COLOR_BLACK_TINT_70;
  blackTint60: $COLOR_BLACK_TINT_60;
  blackTint50: $COLOR_BLACK_TINT_50;
  blackTint40: $COLOR_BLACK_TINT_40;
  blackTint30: $COLOR_BLACK_TINT_30;
  blackTint20: $COLOR_BLACK_TINT_20;
  blackTint10: $COLOR_BLACK_TINT_10;
  blackTint05: $COLOR_BLACK_TINT_05;
  
}
