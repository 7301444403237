@import '../shared.module.scss';

.infoContainer {
  margin-left: 20px;
  margin-right: 20px;
}

.infoContainer > *:not(:last-child) {
  margin-bottom: 4px; 
}

.messageContainer {
  position: relative;
  word-break: break-word;
}

.deleteIcon {
  cursor: pointer;
  position: absolute;
}

.sandButton {
  background-color: $COLOR_SAND;
  margin: 15px;
  text-align: center;
}