@import '../shared.module.scss';

nav {
  @extend .layout-row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background-color: $COLOR_BLACK_TINT_40;
  padding: 4px 4px 4px 4px;
  column-gap: 5px;
  row-gap: 1px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);

  .loginLink {
    text-decoration: none;
    flex-shrink: 0;
    padding: 0;
  }
  .langButtons {
    @extend .layout-row;
  }
  .text {
    display: flex;
    font-size: 1rem;
    margin: 0;
  } 
}

.clickable {
  font-family: 'Lato', sans-serif;
  color: white;
  font-weight: bold;
  font-size: 0.8rem;
  background-color: $COLOR_BLACK_TINT_70;
  margin: 2px;
  border-radius: 40px;
  border: 1px solid #484848;
  padding: 6px 16px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  
  &:hover {
    background-color: $COLOR_BLACK_TINT_60;
  }
}

.loginbutton {
  font-family: 'Lato', sans-serif;
  color: white;
  font-weight: bold;
  font-size: 0.8rem;
  padding: 6px 20px;
}

.paper {
  background: $COLOR_BLACK_TINT_10;
}

.logo {
  width: 60%;
  max-width: 500px;
  min-width: 300px;
  display: block;
  margin-left: 6px;
}

.navList {
  @extend .layout-column;
  gap: 10px;

  font-size: 17px;
  padding: 10px;
  margin-top: 10px;
  width: 200px;
  color: $COLOR_BLACK !important;

  a {
    color: $COLOR_BLACK !important;
    text-decoration: none !important;
  }
}

/* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
@media only screen and (min-width: 320px) and (max-width: 599px) {
  .phone {
    margin-right: 1vw;
    min-width: 48px;
  }

  .logo {
    margin-right: 25vw;
  }

  .loginLink {
    margin-left: 12px;
  }

  .text {
    flex: 50;
    justify-content: right;
  }

  .loginbutton {
    padding: 4px 12px;
  }

  .clickable {
    padding: 4px 8px;
  }

  .langButtons {
    flex: auto;
    justify-content: right;
  }
}

/* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
@media only screen and (min-width: 600px) and (max-width: 799px) {
  .phone {
    display: none;   
  }

  .pc {
    display: inline;
    padding-left: 6px;
    padding-right: 6px;
  }

  .logo {
    margin-right: 40vw;
  }

  .loginLink {
    margin-left: 12px;
  }

  .text {
    flex: 50;
    justify-content: right;
    margin-left: auto;
  }

  .loginbutton {
    padding: 4px 12px;
  }

  .clickable {
    padding: 4px 8px;
  }

  .langButtons {
    flex: auto;
    justify-content: right;
  }
}

/* landscape tablets */
@media only screen and (min-width: 800px) and (max-width: 1024px) {
  nav {
    flex-wrap: nowrap;
  }

  .pc {
    padding-left: 6px;
    padding-right: 6px;
  }

  .logo {
    width: 60%;
  }

  .loginLink {
    margin-left: 10vw;
  }

  .text {
    flex: 1;
    justify-content: right;
  }
}

/* big landscape tablets, laptops, and desktops */
@media only screen and (min-width: 1025px) {
  nav {
    flex-wrap: nowrap;
  }

  .pc {
    padding-left: 6px;
    padding-right: 6px;
  }

  .logo {
    width: 60%;
  }

  .loginLink {
    margin-left: 30vw;
  }

  .text {
    flex: 1;
    justify-content: right;
  }
}