@import '../shared.module.scss';

.paper {
  @extend .layout-column;
  align-items: center;
  margin-top: 64px;
}

.description {
  color: $COLOR_BLACK;
}
