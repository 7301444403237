.email-settings {
  padding: 4vmin 5vmin 4vmin 5vmin;
  margin: auto;
  max-width: 500px;

  legend {
    font-size: 1.4rem;
    //padding-bottom: 15px;
  }
  .group {
    display: grid;
    gap: 12px;
  }
  .component {
    margin-top: 16px;
  }
  .helperText {
    margin: 6px 0px;
  }
}

.settings-label {
  margin-top: 4vh;
}

.results-div {
  margin-top: 2vh;
}

.result-card {
  display: grid;
  grid-template-columns: 3fr 1fr;
}

.result-success {
  background-color: green;
}

.result-failure {
  background-color: red;
}

#send-pending-button {
  max-width: 35%;
}